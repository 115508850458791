.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.App-background {
  max-width: 100%; /* Make the image fit the height of its container */
  position: absolute;
  top: -25%;
}

.App-title {
  font-size: 5em;
  position: absolute;
  top: 25%;
  left: 50%;
  color: white;
  transform: translateX(-50%);
}
